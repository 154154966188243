import { Collection, Card, Button, Text, Flex } from "@aws-amplify/ui-react";
import ImportantButton from "../components/ImportantButton";
import ContentView from "../components/ContentView";
import BlueSearchField from "../components/BlueSearchField";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PageTitle from "../components/PageTitle";

export default function ProjectList(props) {
  const { projectNames } = props;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  function onSearchChanged(event) {
    setSearchText(event.target.value);
  }
  function onSearchCleared() {
    setSearchText("");
  }

  let visibleProjects = projectNames;
  if (searchText) {
    visibleProjects = projectNames.filter((projectName) =>
      projectName.startsWith(searchText)
    );
  }

  return (
    <ContentView>
      <PageTitle>Projects - {projectNames.length} total</PageTitle>
      <Flex>
        <ImportantButton onClick={() => navigate("/projects/new")}>
          Create a New Project
        </ImportantButton>
        <Button onClick={() => navigate("/projects/audit-log")}>
          Audit Log
        </Button>
        <Text style={{ flexGrow: 1 }}></Text>
        <BlueSearchField
          label="Search"
          placeholder="Search"
          onChange={onSearchChanged}
          onClear={onSearchCleared}
        />
      </Flex>
      <Collection
        type="list"
        items={visibleProjects}
        gap="1.5rem"
        direction="row"
        justifyContent="center"
        wrap="wrap"
        isPaginated
        itemsPerPage={12}
        marginTop="60px"
      >
        {(item, index) => (
          <Card key={index} padding="1rem">
            <Button
              width="238px"
              height="138px"
              onClick={() => navigate("/projects/" + item)}
            >
              {item}
            </Button>
          </Card>
        )}
      </Collection>
    </ContentView>
  );
}
