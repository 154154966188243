import { Text, Link, Image, View, Flex } from "@aws-amplify/ui-react";
import ModelTitle from "../components/ModelTitle";
import ModelSubtitle from "../components/ModelSubtitle";
import ModelPaper from "../components/ModelPaper";
import ModelImageTitle from "../components/ModelImageTitle";

export default function PTNet3DInfoPanel(props) {
  const { columnWidth } = props;
  return (
    <>
      <ModelTitle id="ptnet3d" marginTop="30px" marginBottom="10px">
        PTNet3D
      </ModelTitle>
      <ModelSubtitle>
        Longitudinal Infant Brain Synthesis Using PTNet: Imputing Missing or
        Corrupted MRI Data from Birth to 24 Months
      </ModelSubtitle>
      <Flex wrap="wrap">
        <View>
          <Text maxWidth={columnWidth}>
            An increased interest in longitudinal neurodevelopment during the
            first few years after birth has emerged in recent years. Noninvasive
            magnetic resonance imaging (MRI) can provide crucial information
            about the development of brain structures in the early months of
            life. Despite the success of MRI collections and analysis for
            adults, it remains a challenge for researchers to collect
            high-quality multimodal MRIs from developing infant brains because
            of their irregular sleep pattern, limited attention, inability to
            follow instructions to stay still during scanning. In addition,
            there are limited analytic approaches available. These challenges
            often lead to a significant reduction of usable MRI scans and pose a
            problem for modeling neurodevelopmental trajectories. Researchers
            have explored solving this problem by synthesizing realistic MRIs to
            replace corrupted ones. Among synthesis methods, the convolutional
            neural network-based (CNN-based) generative adversarial networks
            (GANs) have demonstrated promising performance. To address this
            challenge, we designed a novel 3D MRI synthesis framework– pyramid
            transformer network (PTNet3D)– which relies on attention mechanisms
            through transformer and performer layers. We conducted extensive
            experiments on high-resolution Developing Human Connectome Project
            (dHCP) and longitudinal Baby Connectome Project (BCP) datasets.
            Compared with CNN-based GANs, PTNet3D consistently shows superior
            synthesis accuracy and superior generalization on two independent,
            large-scale infant brain MRI datasets. Notably, we demonstrate that
            PTNet3D synthesized more realistic scans than CNN-based models when
            the input is from multi-age subjects. Potential applications of
            PTNet3D include synthesizing corrupted or missing images. By
            replacing corrupted scans with synthesized ones, we observed
            significant improvement in infant whole brain segmentation. The code
            is available at&nbsp;
            <Link isExternal={true} href="https://github.com/XuzheZ/PTNet3D">
              github.com/XuzheZ/PTNet3D
            </Link>
            <ModelPaper url="https://ieeexplore.ieee.org/document/9774943" />
          </Text>
        </View>
        <View maxWidth={columnWidth}>
          <ModelImageTitle>PTNet3D Diagram</ModelImageTitle>
          <Image
            src="/models/PTNet3DDiagram.png"
            marginBottom="12px"
            width={columnWidth}
          ></Image>
          <ModelImageTitle>Visualization</ModelImageTitle>
          <Image src="/models/PTNet3DVis.png" width={columnWidth}></Image>
        </View>
        <View maxWidth={columnWidth}></View>
      </Flex>
    </>
  );
}
