import ProjectList from "../components/ProjectList";
import SetupStoragePanel from "../components/SetupStoragePanel";
import AuthenticatedPage from "./AuthenticatedPage";
import { useState, useEffect } from "react";
import { fetchProjects } from "../api/Projects";
import { Placeholder } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

export default function ProjectListPage() {
  const [projectNames, setProjectNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [needsSetup, setNeedsSetup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const names = await fetchProjects();
        setProjectNames(names);
      } catch (error) {
        console.log("Fetchproject failed");
        console.log(error.message);
        setNeedsSetup(true);
      }
      setLoading(false);
    };
    fetchData().catch(console.error);
  }, []);

  async function afterSetup() {
    setLoading(true);
    // clear credentials so reload will start fresh
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    // reload the web page so we can refresh our cognito session
    // this ensures that we are using the new role created during
    // the setup process
    window.location.reload();
  }

  let content;
  if (loading) {
    content = (
      <Placeholder size="large" width="30rem" height="4rem" margin="auto" />
    );
  } else {
    if (needsSetup) {
      content = <SetupStoragePanel afterSetup={afterSetup} />;
    } else {
      content = <ProjectList projectNames={projectNames} />;
    }
  }

  return (
    <AuthenticatedPage title="Projects" selectedNavPath="/projects">
      {content}
    </AuthenticatedPage>
  );
}
