import { View, TextField, ButtonGroup } from "@aws-amplify/ui-react";
import { useState } from "react";
import { createProject } from "../api/Projects";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import ImportantButton from "../components/ImportantButton";
import BackButton from "../components/BackButton";

export default function NewProjectForm(props) {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const projectNameRegex = new RegExp("^[A-Za-z0-9-_]+$");

  async function onClickCreate() {
    if (projectNameRegex.test(name)) {
      // create project in s3 (prevents duplicates)
      const result = await createProject(name);
      // create project in dynamodb
      await API.post("projects", "/projects", {
        body: {
          name: name,
        },
      });
      navigate("/projects/" + result.key);
    } else {
    }
  }

  return (
    <View>
      <TextField
        backgroundColor="white"
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        marginBottom="2em"
      />
      <ButtonGroup>
        <ImportantButton onClick={onClickCreate}>
          Create Project
        </ImportantButton>
        <BackButton />
      </ButtonGroup>
    </View>
  );
}
