import { useParams } from "react-router-dom";
import FilesTable from "../components/FilesTable";
import AuthenticatedPage from "./AuthenticatedPage";
import ContentView from "../components/ContentView";
import PageTitle from "../components/PageTitle";

export default function ProjectPage() {
  let params = useParams();
  return (
    <AuthenticatedPage selectedNavPath="/projects">
      <ContentView>
        <PageTitle>Project {params.projectId}</PageTitle>
        <FilesTable project={params.projectId} margin="0.5rem" />
      </ContentView>
    </AuthenticatedPage>
  );
}
