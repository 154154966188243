import { Flex, View, Image } from "@aws-amplify/ui-react";

const IMAGE_HEIGHT = "100px";

export default function Person({ name, title, url, height }) {
  return (
    <View display="inline-block" margin="0.5rem">
      <View>
        <Image src={url} alt={name} height="140px" width="fit-content"></Image>
      </View>
      <View fontSize="small">{name}</View>
      <View fontSize="small">{title}</View>
    </View>
  );
}
