import {
  View,
  Text,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Placeholder,
  Button,
} from "@aws-amplify/ui-react";
import { useState, useEffect } from "react";
import { Storage, API, Auth } from "aws-amplify";
import ImportantButton from "./ImportantButton";
import ErrorAlert from "../components/ErrorAlert";
import WorkingAlert from "../components/WorkingAlert";
import ContentView from "../components/ContentView";
import PageTitle from "../components/PageTitle";

const WORKING_MESSAGE = "Please wait...";

export default function AuditLogGenerator() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function generateAuditLog() {
    setErrorMessage("");
    setLoading(true);
    try {
      const result = await API.post("auditlogs", "/auditlogs");
      const url = await Storage.get(result.event_log_path, {
        level: "private",
      });
      window.open(url, "_blank");
    } catch (error) {
      window.scrollTo(0, 0);
      console.log(error);
      setErrorMessage(error.toString());
    }
    setLoading(false);
  }
  let alert = null;
  if (loading) {
    alert = <WorkingAlert>{WORKING_MESSAGE}</WorkingAlert>;
  }
  if (errorMessage) {
    alert = <ErrorAlert>{errorMessage}</ErrorAlert>;
  }
  let downloadButton = null;
  return (
    <>
      {alert}
      <ContentView>
        <PageTitle>Audit Log</PageTitle>
        <Text maxWidth={600} marginBottom="1em">
          Log entries are automaticaly captured when files are uploaded or
          download within your secure storage environment. Due the the nature of
          the cloud infrastructure these log entries can take up to 5 minutes to
          appear after the upload or download event. Click the button below to
          generate a zip archive containing CSV files with event information.
          The download will automatically begin once the archive is created.
        </Text>

        <ImportantButton isLoading={loading} onClick={generateAuditLog}>
          Create Audit Log Archive
        </ImportantButton>
        {downloadButton}
      </ContentView>
    </>
  );
}
