import { Text } from "@aws-amplify/ui-react";

export default function ModelImageTitle(props) {
  const { children } = props;
  return (
    <Text marginBottom="8px" fontWeight="bold">
      {children}
    </Text>
  );
}
