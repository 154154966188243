import { Text, Link, Image, View, Flex } from "@aws-amplify/ui-react";
import ModelTitle from "../components/ModelTitle";
import ModelSubtitle from "../components/ModelSubtitle";
import ModelPaper from "../components/ModelPaper";
import ModelImageTitle from "../components/ModelImageTitle";
import {MODEL_TIP_COLOR} from "../components/Theme";

export default function MapSegInfoPanel(props) {
  const { columnWidth } = props;
  return (
    <>
      <ModelTitle id="mapseg" marginBottom="10px">
        MapSeg
        <Text as="span" fontSize="small" fontWeight="normal" fontStyle="italic">
          &nbsp; (coming soon)
        </Text>
      </ModelTitle>
      <ModelSubtitle>
        Multi-site, Multi-age, and Multi-modal Subcortical Brain Segmentation
        from Birth to 24 Months
      </ModelSubtitle>
      <Text
        fontSize="small"
        fontWeight="normal"
        fontStyle="italic"
        marginBottom="10px"
      >
        An older version of this model is already available under the name
        cloud-infant-seg-v0-3-0.
      </Text>
      <Flex wrap="wrap">
        <View maxWidth={columnWidth}>
          <Text as="span">
            Robust segmentation of infant brain MRI across multiple ages,
            modalities, and sites remains challenging due to the intrinsic
            heterogeneity caused by different MRI scanners, vendors, or
            acquisition sequences, as well as varying stages of
            neurodevelopment. We designed a novel framework called MAPSeg
            (Masked Autoencoding and Pseudo-labelling Segmentation) to address
            the challenges of cross-age, cross-modality, and cross-site
            segmentation of subcortical regions in infant brain MRI. Utilizing
            3D masked autoencoding as well as masked pseudo-labeling, the model
            is able to jointly learn from labeled source domain data and
            unlabeled target domain data. We evaluated our framework on
            expert-annotated datasets acquired from different ages and sites.
            MAPSeg consistently outperformed other methods, including previous
            state-of-the-art supervised baselines, domain generalization, and
            domain adaptation frameworks in segmenting subcortical regions
            regardless of age, modality, or acquisition site. The code and
            pretrained encoder will be publicly available at{" "}
            <Link isExternal={true} href="https://github.com/XuzheZ/MAPSeg">
              https://github.com/XuzheZ/MAPSeg
            </Link>
            .
          </Text>
          <ModelPaper url="https://arxiv.org/abs/2303.09373" />
          <br />
          <Text color={MODEL_TIP_COLOR}>
            Tip: We suggest you perform skull-stripping before uploading the MRI data for segmentation.
          </Text>
        </View>
        <View>
          <ModelImageTitle>Segmentation model diagram</ModelImageTitle>
          <Image
            src="/models/MapSegModelDiagram.png"
            marginBottom="12px"
            width={columnWidth}
          ></Image>
          <ModelImageTitle>Visualization</ModelImageTitle>
          <Image src="/models/MapSegVis.png" width={columnWidth}></Image>
        </View>
      </Flex>
    </>
  );
}
