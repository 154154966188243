import AuthenticatedPage from "./AuthenticatedPage";
import AuditLogGenerator from "../components/AuditLogGenerator";

export default function AuditPage() {
  return (
    <AuthenticatedPage selectedNavPath="/projects">
      <AuditLogGenerator />
    </AuthenticatedPage>
  );
}
