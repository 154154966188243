import { Link, Text } from "@aws-amplify/ui-react";

const CONTACT_EMAIL = "finneas@duke.edu";

export default function ContactLink(props) {
  const { ...rest } = props;

  const contactHref = `mailto:${CONTACT_EMAIL}`;
  return (
    <Text as="span" color="white" fontSize="1.5em">
      We welcome users to&nbsp;
      <Link
        color="white"
        textDecoration="underline"
        href={contactHref}
        {...rest}
      >
        email us
      </Link>
      &nbsp;and test our prototype.
    </Text>
  );
}
