import NavBar from "../components/NavBar";
import ContentView from "../components/ContentView";
import PageTitle from "../components/PageTitle";
import MapSegInfoPanel from "../components/MapSegInfoPanel";
import PTNet3DInfoPanel from "../components/PTNet3DInfoPanel";
import { Text, View, Link } from "@aws-amplify/ui-react";

const COLUMN_WIDTH = "500px";

export default function ModelsPage() {
  return (
    <>
      <NavBar selectedNavPath="/models" />
      <ContentView>
        <PageTitle>Model Comparision</PageTitle>
        <Text fontSize="1.25em" fontWeight="bold">
          Models
        </Text>
        <ul>
          <li>
            <Link href="#mapseg">MapSeg</Link>
          </li>
          <li>
            <Link href="#ptnet3d">PTNet3D</Link>
          </li>
        </ul>
        <View>
          <MapSegInfoPanel columnWidth={COLUMN_WIDTH} />
          <br />
          <hr />
          <PTNet3DInfoPanel columnWidth={COLUMN_WIDTH} />
        </View>
      </ContentView>
    </>
  );
}
