import { Text, Link } from "@aws-amplify/ui-react";

export default function LaunchModelInfo(props) {
  const { endpoint, files } = props;
  const fileOptions = files.map((fileKey) => <li key={fileKey}>{fileKey}</li>);
  return (
    <>
      <Text fontWeight="bold" fontSize="large">
        Model Details
      </Text>
      <Text>Name: {endpoint.EndpointName}</Text>
      <br />
      <Text>{endpoint.Instructions}</Text>
      <br />
      <Text marginBottom="1em">
        Model source code:&nbsp;
        <Link isExternal={true} href={endpoint.SourceCodeURL}>
          {endpoint.SourceCodeURL}
        </Link>
      </Text>
      <Text fontWeight="bold" fontSize="large">
        Input Files
      </Text>
      <ul>{fileOptions}</ul>
      <Text fontWeight="bold" fontSize="large">
        Output Files
      </Text>
      <Text>
        Your output files will be in the same project folder as the input files
        with the prefix you defined.
      </Text>
    </>
  );
}
