import { Text, Flex } from "@aws-amplify/ui-react";
import NewProjectForm from "../components/NewProjectForm";
import AuthenticatedPage from "./AuthenticatedPage";
import ContentView from "../components/ContentView";
import ActionCard from "../components/ActionCard";
import InfoCard from "../components/InfoCard";
import NewProjectInfo from "../components/NewProjectInfo";
import PageTitle from "../components/PageTitle";

export default function NewProjectPage() {
  return (
    <AuthenticatedPage title="New Project" selectedNavPath="/projects">
      <ContentView>
        <PageTitle>New Project</PageTitle>
        <Flex>
          <ActionCard>
            <Text fontWeight="bold" fontSize="large" marginBottom="1em">
              Project Settings
            </Text>
            <NewProjectForm />
          </ActionCard>
          <InfoCard>
            <NewProjectInfo />
          </InfoCard>
        </Flex>
      </ContentView>
    </AuthenticatedPage>
  );
}
