import NavBar from "../components/NavBar";
import ContentView from "../components/ContentView";
import { View, Link, Text, Image, Flex } from "@aws-amplify/ui-react";
import TeamPanel from "../components/TeamPanel";
import PageTitle from "../components/PageTitle";
import { useNavigate } from "react-router-dom";

export default function AboutPage() {
  const navigate = useNavigate();
  return (
    <>
      <NavBar selectedNavPath="/about" />
      <ContentView>
        <PageTitle>About</PageTitle>
        <Flex>
          <View maxWidth="600px">
            <Text>
              In FINNEAS, a federated domain adaptation framework (see Fig 1.)
              is the engine, and a cloud-based web platform is the wheel. This
              platform will help researchers from non-technical backgrounds to
              streamline infant neuroimaging analysis (Fig 2.) with novel AI
              models while preserving{" "}
              <Link
                textDecoration="underline"
                onClick={() => navigate("/about/data-security")}
              >
                data privacy
              </Link>
              .
            </Text>
            <br />
            <Text>
              The source code for this website is at &nbsp;
              <a href="https://github.com/InfantBrainAI/CloudInfantUI">
                github.com/InfantBrainAI/CloudInfantUI
              </a>
              .
            </Text>
            <Text
              fontSize="1.5em"
              fontWeight="bold"
              marginTop="30px"
              marginBottom="10px"
            >
              Team
            </Text>
            <Text>
              The website FINNEAS.AI is primarily developed and supported by the NexMii Lab
              within the Department of Biomedical Informatics at Emory University, in
              collaboration with Biomedical Engineering at Columbia University and
              Computer Science at the University of Maryland.
            </Text>
          </View>
          <View wrap="nowrap">
            <Image
              minWidth="479"
              width="479"
              display="block"
              objectFit="contain"
              src="/high-level-diagram.png"
            ></Image>
            <Image
              minWidth="446"
              width="446"
              margin="20px"
              display="block"
              src="/threebrains.png"
            ></Image>
          </View>
        </Flex>
        <br />
        <Flex>
          <TeamPanel />
        </Flex>
      </ContentView>
    </>
  );
}
