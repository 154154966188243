// colors named based on Figma protype
export const ICON_COLOR = "#FFBE41";
export const BUTTON_BACKGROUND_COLOR = "#FFBE41";
export const NAV_TEXT_COLOR = "white";
export const SELECT_NAV_LINK_BOTTOM_BORDER = "4px solid #FFBE41";
export const EASY_TO_USE_BACKGROUND_COLOR = "#0261BA";
export const LEARN_ABOUT_MODELS_BACKGROUND_COLOR = "#D9E7F5";
export const MODEL_COMPARE_BUTTON_COLOR = "#0261BA";
export const CONTACT_US_BACKGROUND_COLOR = "#474039";
export const SEARCH_BOX_COLOR = "#0261BA";
export const ACTION_BUTTON_COLOR = "#0261BA";
export const ACTION_PANEL_COLOR = "#D9E7F5";
export const WORKING_PANEL_BGCOLOR = "#12AF7F";
export const SECONDARY_BUTTON_COLOR = "#0261BA";
export const SECONDARY_BUTTON_BACKGROUND_COLOR = "white";
export const INFO_PANEL_COLOR = "white";
export const MODEL_TIP_COLOR = "#EC4329";
