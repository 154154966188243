/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "auditlogs",
            "endpoint": "https://4o93vqr9x5.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "endpoints",
            "endpoint": "https://fm36ekf7g9.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "jobs",
            "endpoint": "https://tshlyy7nef.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "jobs2",
            "endpoint": "https://at5ce1ckke.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "launch",
            "endpoint": "https://usrcfiy8ik.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "projects",
            "endpoint": "https://wntc5r1xn8.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "storage",
            "endpoint": "https://ph1ifbthm7.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "tasks",
            "endpoint": "https://8ktzg3ttr6.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "urls",
            "endpoint": "https://20v39xzgca.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "user",
            "endpoint": "https://6wst3jwq72.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:921990df-c4df-4839-b37c-b259bc392939",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lisdsNEfR",
    "aws_user_pools_web_client_id": "oa6kj2dgp08225011vntffukf",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "job-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "project-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "task-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "user-prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "user-data123957-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
