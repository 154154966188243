import JobsTable from "../components/JobsTable";
import AuthenticatedPage from "./AuthenticatedPage";
import ContentView from "../components/ContentView";
import PageTitle from "../components/PageTitle";

export default function DashboardPage(props) {
  return (
    <AuthenticatedPage title="Dashboard" selectedNavPath="/dashboard">
      <ContentView>
        <PageTitle>Task Progress</PageTitle>
        <JobsTable refresh={true} />
      </ContentView>
    </AuthenticatedPage>
  );
}
