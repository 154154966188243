import { View } from "@aws-amplify/ui-react";
import { INFO_PANEL_COLOR } from "./Theme";

export default function InfoCard(props) {
  const { children, ...rest } = props;
  return (
    <View backgroundColor={INFO_PANEL_COLOR} className="infoCard" {...rest}>
      {children}
    </View>
  );
}
