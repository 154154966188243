import { useState, useEffect } from "react";
import { listFiles, deleteFile } from "../api/Projects";
import { useNavigate } from "react-router-dom";
import {
  View,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  CheckboxField,
  Flex,
} from "@aws-amplify/ui-react";
import { downloadFile } from "../api/Projects";
import SortTitle from "../components/SortTitle";
import BlueSearchField from "../components/BlueSearchField";
import ImportantButton from "../components/ImportantButton";
import ActionButton from "../components/ActionButton";

const formatter = new Intl.DateTimeFormat("en-US", {
  dateStyle: "medium",
  timeStyle: "short",
});

function guessFileType(filename) {
  if (filename.endsWith(".gz")) {
    return filename.split(".", 2).pop();
  }
  return filename.split(".").pop();
}

function lookupColor(filename) {
  if (guessFileType(filename) === "zip") {
    return "green";
  }
  return "black";
}

export default function FilesTable(props) {
  const { project, ...rest } = props;
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [values, setValues] = useState([]);
  let navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  async function onClickDownload(key) {
    const url = await downloadFile(key);
    window.open(url, "_blank");
  }

  function onClickSelectAll(event) {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedValues(values);
    } else {
      setSelectedValues([]);
    }
  }

  function refreshList() {
    setSelectAll(false);
    setRefreshKey(refreshKey + 1);
  }

  function launchForFiles() {
    const fileIds = [];
    for (const fileObject of selectedValues) {
      fileIds.push(fileObject.key);
    }
    navigate("launch/", { state: { fileIds: fileIds } });
  }

  async function deleteSelectedFiles() {
    for (const fileObject of selectedValues) {
      await deleteFile(fileObject.key);
    }
    refreshList();
  }

  function updateSelection(x, checked) {
    if (checked) {
      setSelectedValues((selectedValues) => [...selectedValues, x]);
    } else {
      setSelectedValues(
        selectedValues.filter(function (fileObject) {
          return fileObject !== x;
        })
      );
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await listFiles(project);
      setValues(result);
      setSelectedValues([]);
    };
    fetchData().catch(console.error);
  }, [refreshKey, project]);

  const hasSelectedFiles = selectedValues.length > 0;

  const selectedKeys = selectedValues.map((x) => x.key);
  let filteredValues = values;
  if (searchValue) {
    filteredValues = values.filter(function (x) {
      return (
        x.name.startsWith(searchValue) || guessFileType(x.name) === searchValue
      );
    });
  }
  const rows = filteredValues.map((x) => (
    <TableRow key={x.key}>
      <TableCell>
        <CheckboxField
          checked={selectedKeys.includes(x.key)}
          onChange={(e) => updateSelection(x, e.target.checked)}
        />
      </TableCell>
      <TableCell color={lookupColor(x.name)}>{x.name}</TableCell>
      <TableCell>{guessFileType(x.name)}</TableCell>
      <TableCell>{formatter.format(x.lastModified)}</TableCell>
      <TableCell>{x.size}</TableCell>
      <TableCell>
        <ActionButton onClick={() => onClickDownload(x.key)}>
          Download
        </ActionButton>
      </TableCell>
    </TableRow>
  ));
  return (
    <View {...rest}>
      <Flex>
        <Flex marginBottom="0.5rem">
          <ImportantButton onClick={() => navigate("upload")}>
            Upload Files
          </ImportantButton>
          <Button isDisabled={!hasSelectedFiles} onClick={launchForFiles}>
            Launch Task
          </Button>
          <Button onClick={refreshList}>Refresh</Button>
          <Button isDisabled={!hasSelectedFiles} onClick={deleteSelectedFiles}>
            Delete
          </Button>
        </Flex>
        <View grow="1"></View>
        <View display="inline-block" marginLeft="0.5rem">
          <BlueSearchField
            display="inline-block"
            label="Search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </View>
      </Flex>
      <Table highlightOnHover={false} size={undefined} variation={"striped"}>
        <TableHead>
          <TableRow>
            <TableCell as="th" border="none">
              <CheckboxField checked={selectAll} onChange={onClickSelectAll} />
            </TableCell>
            <TableCell as="th" border="none">
              Filename
            </TableCell>
            <TableCell as="th" border="none">
              <SortTitle title="FileType" />
            </TableCell>
            <TableCell as="th" border="none">
              Last Modified
            </TableCell>
            <TableCell as="th" border="none">
              Size
            </TableCell>
            <TableCell as="th" border="none">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </View>
  );
}
