import AuthenticatedPage from "./AuthenticatedPage";
import LaunchPanel from "../components/LaunchPanel";
import { useParams, useLocation } from "react-router-dom";

export default function LaunchPage() {
  const location = useLocation();
  const params = useParams();
  const files = location.state.fileIds;

  return (
    <AuthenticatedPage selectedNavPath="/projects">
      <LaunchPanel projectId={params.projectId} files={files} />
    </AuthenticatedPage>
  );
}
