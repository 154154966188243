import { Alert, Text, Loader } from "@aws-amplify/ui-react";
import { WORKING_PANEL_BGCOLOR } from "./Theme";

export default function WorkingAlert(props) {
  return (
    <Alert
      textAlign="center"
      backgroundColor={WORKING_PANEL_BGCOLOR}
      hasIcon={false}
      padding="1em"
    >
      <Text>
        <Loader style={{ verticalAlign: "middle" }} /> &nbsp;
        {props.children}
      </Text>
    </Alert>
  );
}
