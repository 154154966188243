import { BUTTON_BACKGROUND_COLOR } from "../components/Theme";
import { Button } from "@aws-amplify/ui-react";

export default function ImportantButton(props) {
  const { onClick, children, ...rest } = props;
  return (
    <Button
      onClick={onClick}
      backgroundColor={BUTTON_BACKGROUND_COLOR}
      {...rest}
    >
      {children}
    </Button>
  );
}
