import {
  SECONDARY_BUTTON_COLOR,
  SECONDARY_BUTTON_BACKGROUND_COLOR,
} from "../components/Theme";
import { Button } from "@aws-amplify/ui-react";

export default function SecondaryButton(props) {
  const { onClick, children, ...rest } = props;
  return (
    <Button
      onClick={onClick}
      color={SECONDARY_BUTTON_COLOR}
      backgroundColor={SECONDARY_BUTTON_BACKGROUND_COLOR}
      {...rest}
    >
      {children}
    </Button>
  );
}
