import { Alert, Text } from "@aws-amplify/ui-react";
import { IoWarning } from "react-icons/io5";

export default function PHIAlert() {
  return (
    <Alert textAlign="center" variation="warning" hasIcon={false} padding="1em">
      <Text>
        <IoWarning size={24} style={{ verticalAlign: "middle" }} />
        &nbsp; Warning: Please do not upload MRI data containing Patient Health
        Information.
      </Text>
    </Alert>
  );
}
