import { View, Text } from "@aws-amplify/ui-react";

export default function NewProjectInfo() {
  return (
    <View>
      <Text fontWeight="bold" fontSize="large" marginBottom="1em">
        Project Name Rules
      </Text>
      <Text>
        Project names can only contain Alphanumeric characters, hyphen(-), and
        underscore(_).
      </Text>
    </View>
  );
}
