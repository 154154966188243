import { View, Text } from "@aws-amplify/ui-react";
import RequireAuth from "../components/RequireAuth";
import NavBar from "../components/NavBar";

export default function AuthenticatedPage(props) {
  return (
    <RequireAuth>
      <NavBar selectedNavPath={props.selectedNavPath} />
      <View>{props.children}</View>
    </RequireAuth>
  );
}
