import { createRef, useState } from "react";
import { uploadFiles } from "../api/Projects";
import { useNavigate } from "react-router-dom";
import { View, Text, Flex, ButtonGroup } from "@aws-amplify/ui-react";
import ImportantButton from "../components/ImportantButton";
import ContentView from "../components/ContentView";
import PHIAlert from "../components/PHIAlert";
import ErrorAlert from "../components/ErrorAlert";
import WorkingAlert from "./WorkingAlert";
import ActionCard from "../components/ActionCard";
import InfoCard from "../components/InfoCard";
import PageTitle from "../components/PageTitle";
import BackButton from "../components/BackButton";

export default function FileUploadPanel(props) {
  const fileInput = createRef();
  const navigate = useNavigate();
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    if (fileInput.current.files.length === 0) {
      setErrorMessage("You must select one or more files to upload first.");
    } else {
      setUploadingFiles(true);
      setErrorMessage("");
      setUploadMessage(`Uploading ${fileInput.current.files.length} file(s).`);
      await uploadFiles(props.project, fileInput.current.files);
      navigate("/projects/" + props.project);
    }
  }

  let alert = <PHIAlert />;
  if (uploadingFiles) {
    alert = <WorkingAlert>{uploadMessage}</WorkingAlert>;
  }
  if (errorMessage) {
    alert = <ErrorAlert>{errorMessage}</ErrorAlert>;
  }

  return (
    <>
      {alert}
      <ContentView>
        <PageTitle>Project {props.project} - File Upload</PageTitle>

        <Flex>
          <ActionCard as="form" onSubmit={handleSubmit}>
            <h3>Select files to upload</h3>
            <p>
              Select&nbsp;
              <a
                href="https://nifti.nimh.nih.gov/"
                target="_blank"
                rel="noreferrer"
              >
                NIfTI
              </a>
              &nbsp;files with extensions .nii or .nii.gz to be uploaded.
              Uploaded files can be used as inputs for running tasks using
              FINNEAS models.
            </p>
            <div>
              <input type="file" ref={fileInput} multiple accept=".nii,.gz" />
            </div>
            <br />
            <ButtonGroup>
              <ImportantButton type="submit" disabled={uploadingFiles}>
                Upload
              </ImportantButton>
              <BackButton />
            </ButtonGroup>
          </ActionCard>
          <InfoCard>
            <Text fontWeight="bold">Data Security</Text>
            <View>
              Any files uploaded will be encrypted with your key and stored in
              your bucket. Other users will not be able to access your files.
            </View>
          </InfoCard>
        </Flex>
      </ContentView>
    </>
  );
}
