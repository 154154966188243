import { useParams } from "react-router-dom";
import AuthenticatedPage from "./AuthenticatedPage";

export default function FilesPage() {
  let params = useParams();
  return (
    <AuthenticatedPage selectedNavPath="/projects">
      <h2>View Files Page</h2>
      <h3>Project {params.projectId} Files</h3>
    </AuthenticatedPage>
  );
}
