import {
  KMSClient,
  DescribeKeyCommand,
  CreateGrantCommand,
} from "@aws-sdk/client-kms";
import { API, Auth } from "aws-amplify";

async function createKmsClient() {
  const cred = await Auth.currentCredentials();
  return new KMSClient({
    region: Auth._config.region,
    credentials: Auth.essentialCredentials(cred),
  });
}

async function getUserId() {
  const userInfo = await Auth.currentUserInfo();
  return userInfo.username;
}

async function getKeyArn(kmsClient, userId) {
  const describeKey = new DescribeKeyCommand({
    KeyId: "alias/finkey-" + userId,
  });
  const response = await kmsClient.send(describeKey);
  return response.KeyMetadata.Arn;
}

async function createGrant(
  kmsClient,
  name,
  keyArn,
  granteePrincipal,
  retiringPrincipal
) {
  const command = new CreateGrantCommand({
    KeyId: keyArn,
    Name: name,
    GranteePrincipal: granteePrincipal,
    RetiringPrincipal: retiringPrincipal,
    Operations: ["Decrypt", "Encrypt", "GenerateDataKey"],
  });
  const response = await kmsClient.send(command);
  return response["GrantToken"];
}

async function createGrants(
  kmsClient,
  nameSuffix,
  keyArn,
  sagemakerArn,
  taskCompleteArn
) {
  const sagemakerGrantToken = await createGrant(
    kmsClient,
    "sm-" + nameSuffix,
    keyArn,
    sagemakerArn,
    taskCompleteArn
  );
  const zipGrantToken = await createGrant(
    kmsClient,
    "zip-" + nameSuffix,
    keyArn,
    taskCompleteArn,
    taskCompleteArn
  );
  return {
    sagemakerGrantToken: sagemakerGrantToken,
    zipGrantToken: zipGrantToken,
  };
}

export async function createMLGrants(grantUUID, infrastructureRoles) {
  console.log(infrastructureRoles);
  const kmsClient = await createKmsClient();
  const userId = await getUserId();
  const keyArn = await getKeyArn(kmsClient, userId);
  const { sagemakerGrantToken, zipGrantToken } = await createGrants(
    kmsClient,
    grantUUID,
    keyArn,
    infrastructureRoles.sagemaker,
    infrastructureRoles.zipresults
  );
  return {
    keyArn: keyArn,
    grantTokens: [sagemakerGrantToken, zipGrantToken],
  };
}

function getFilenamePrefix(endpointName, outputPrefix) {
  let filenamePrefix = endpointName;
  if (outputPrefix && outputPrefix !== "") {
    filenamePrefix = outputPrefix;
  }
  return filenamePrefix;
}
export const STUFF = "ABC";

export function applyFilenameSchema(filenameSchema, filename) {
  if (filenameSchema === "T1toT2") {
    if (filename.includes("T1w")) {
      filename = filename.replace("T1w", "T2w");
    } else {
      filename = filename.replace("T1", "T2");
    }
  } else if (filenameSchema === "T2toT1") {
    if (filename.includes("T2w")) {
      filename = filename.replace("T2w", "T1w");
    } else {
      filename = filename.replace("T2", "T1");
    }
  }
  return filename;
}

export function formatOutputFilename(
  endpointName,
  outputPrefix,
  filenameSchema,
  fileKey
) {
  const filenamePrefix = getFilenamePrefix(endpointName, outputPrefix);
  const fileKeyParts = fileKey.split("/");
  const originalFilename = fileKeyParts[fileKeyParts.length - 1];
  let simpleFilename = originalFilename;
  if (filenameSchema) {
    simpleFilename = applyFilenameSchema(filenameSchema, simpleFilename);
  }
  fileKeyParts[fileKeyParts.length - 1] = filenamePrefix + "-" + simpleFilename;
  return fileKeyParts.join("/");
}

export async function createJob(
  bucket,
  endpointName,
  outputPrefix,
  fileIds,
  grants,
  filenameSchema
) {
  const projectName = fileIds[0].split("/")[0];
  var tasks = [];
  fileIds.forEach(function (fileKey) {
    const destFileKey = formatOutputFilename(
      endpointName,
      outputPrefix,
      filenameSchema,
      fileKey
    );
    tasks.push({
      input: {
        bucket: bucket,
        object: `${fileKey}`,
      },
      output: {
        bucket: bucket,
        object: `${destFileKey}`,
      },
    });
  });
  var body = {
    projectName: projectName,
    model: endpointName,
    tasks: tasks,
    filenamePrefix: getFilenamePrefix(endpointName, outputPrefix),
    grants: grants,
  };
  await API.post("jobs", "/jobs", {
    body: body,
  });
  return fileIds.length;
}
