import { ACTION_BUTTON_COLOR } from "../components/Theme";
import { Button } from "@aws-amplify/ui-react";

export default function ActionButton(props) {
  const { onClick, children, rest } = props;
  return (
    <Button
      onClick={onClick}
      size="small"
      style={{
        border: "1px solid " + ACTION_BUTTON_COLOR,
        color: ACTION_BUTTON_COLOR,
        whiteSpace: "nowrap",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}
