import {
  Flex,
  Text,
  View,
  Link,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Placeholder,
  Loader,
  Button,
} from "@aws-amplify/ui-react";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { removeKeyPrefix, downloadFile } from "../api/Projects";
import ActionButton from "../components/ActionButton";

const STATE_COMPLETE_WITH_RESULTS = "completeWithResults";
const STATE_COMPLETE = "complete";
const STATE_PACKAGING_RESULTS = "packagingResults";
const STATE_WARNING = "warning";
const STATE_WORKING = "working";

const formatter = new Intl.DateTimeFormat("en-US", {
  dateStyle: "medium",
  timeStyle: "short",
});

function getState(job) {
  const { succeededTasks, totalTasks, failedTasks, output, complete } = job;
  const succeeded = parseInt(succeededTasks);
  const failed = parseInt(failedTasks);
  const total = parseInt(totalTasks);
  if (succeeded === total) {
    if (output) {
      if (complete) {
        return STATE_COMPLETE_WITH_RESULTS;
      } else {
        return STATE_PACKAGING_RESULTS;
      }
    } else {
      return STATE_COMPLETE;
    }
  }
  if (failed > 0) {
    return STATE_WARNING;
  }
  return STATE_WORKING;
}

function getStatus(job) {
  switch (getState(job)) {
    case STATE_COMPLETE_WITH_RESULTS:
    case STATE_COMPLETE:
      return (
        <span title="Complete">
          <FaCheckCircle style={{ color: "green" }} />
        </span>
      );
    case STATE_WARNING:
      return (
        <span title="Warning: Some tasks failed">
          <FaExclamationTriangle style={{ color: "OrangeRed" }} />
        </span>
      );
    case STATE_WORKING:
      return (
        <span title="Running">
          <Loader size="small" />
        </span>
      );
    case STATE_PACKAGING_RESULTS:
      return (
        <span title="Creating results zip file">
          <Loader size="small" />
        </span>
      );
    default:
      return <FaExclamationTriangle />;
  }
}

export default function JobsTable(props) {
  const { project, refresh, ...rest } = props;
  const refreshSeconds = 30;
  let navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const [refreshLabel, setRefreshLabel] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      var result = await API.get("jobs", "/jobs");
      if (project) {
        result = result.filter((p) => p.project === project);
      }
      setJobs(result);
      setLoading(false);
      if (refresh) {
        setRefreshLabel(`Refreshes every ${refreshSeconds} seconds`);
      }
    };
    fetchData().catch(console.error);
  }, [refreshKey, project, refresh]);

  useEffect(() => {
    if (refresh) {
      const interval = setInterval(() => {
        setRefreshKey(refreshKey + 1);
        setRefreshLabel("Refreshing...");
      }, refreshSeconds * 1000);
      return () => clearInterval(interval);
    }
  }, [refreshKey, refresh]);

  async function onClickDownloadResults(job) {
    const key = removeKeyPrefix(job.output.object);
    const url = await downloadFile(key);
    window.open(url, "_blank");
  }

  function getActions(job) {
    if (getState(job) === STATE_COMPLETE_WITH_RESULTS) {
      return (
        <ActionButton size="small" onClick={() => onClickDownloadResults(job)}>
          Download Results
        </ActionButton>
      );
    }
    return null;
  }

  const rows = jobs.map((job) => (
    <TableRow key={job.id}>
      <TableCell>{getStatus(job)}</TableCell>
      <TableCell>
        <Link onClick={() => navigate("/projects/" + job.projectName)}>
          {job.projectName}
        </Link>
      </TableCell>
      <TableCell>{job.model}</TableCell>
      <TableCell>
        {job.totalTasks} Tasks:&nbsp;
        {parseInt(job.succeededTasks) + parseInt(job.failedTasks)}{" "}
        Completed,&nbsp;
        {job.failedTasks} Failed
      </TableCell>
      <TableCell>{formatter.format(new Date(job.created))}</TableCell>
      <TableCell>{formatter.format(new Date(job.updated))}</TableCell>
      <TableCell as="th">{getActions(job)}</TableCell>
    </TableRow>
  ));

  return (
    <View {...rest}>
      <Flex margin="0.5rem">
        <Text as="span" flex={1} textAlign="right">
          {refreshLabel}
        </Text>
      </Flex>
      <View>
        <Table highlightOnHover={false} size={undefined} variation={"striped"}>
          <TableHead>
            <TableRow>
              <TableCell as="th" border="none"></TableCell>
              <TableCell as="th" border="none">
                Project
              </TableCell>
              <TableCell as="th" border="none">
                Model
              </TableCell>
              <TableCell as="th" border="none">
                Details
              </TableCell>
              <TableCell as="th" border="none">
                Created
              </TableCell>
              <TableCell as="th" border="none">
                Last Updated
              </TableCell>
              <TableCell as="th" border="none">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
        {loading && <Placeholder size="large" />}
      </View>
    </View>
  );
}
