import { IoPersonCircle } from "react-icons/io5";
import { View, Text, Button, Link } from "@aws-amplify/ui-react";
import { ICON_COLOR, NAV_TEXT_COLOR } from "../components/Theme";
export default function SignInOutPanel(props) {
  const personIcon = (
    <IoPersonCircle
      style={{ verticalAlign: "middle" }}
      color={ICON_COLOR}
      fontSize="2em"
    />
  );
  const { user, navigate, signOut } = props;
  let iconLabel = (
    <Link variation="link" color="white" onClick={() => navigate("/projects")}>
      Sign In / Register
    </Link>
  );
  let logoutButton = null;
  if (user) {
    iconLabel = user.attributes.email;
    logoutButton = (
      <View display="inline-block" marginTop="6px">
        <Button
          color="white"
          display="inline-block"
          whiteSpace="nowrap"
          size="small"
          onClick={signOut}
        >
          Sign Out
        </Button>
      </View>
    );
  }

  return (
    <>
      <View marginTop="8px">
        <Text whiteSpace="nowrap" color={NAV_TEXT_COLOR}>
          {personIcon}
          &nbsp;
          {iconLabel}
        </Text>
      </View>
      {logoutButton}
    </>
  );
}

/*
<div>
    <IoPersonCircle />
    <Button
      variation="menu"
      style={{ color: "white" }}
      size="small"
      onClick={() => navigate("/dashboard")}
    >
      Sign In / Register
    </Button>
  </div>;
*/

/*
<div>
        <IoPersonCircle />
        <Text
          as="span"
          lineHeight="2em"
          color="white"
          fontStyle="italic"
          fontSize=".8em"
        >
          Logged in as: {user.attributes.email}
        </Text>
        <Button
          variation="menu"
          style={{ color: "white" }}
          size="small"
          onClick={signOut}
        >
          Sign out
        </Button>
      </div>
      */
