import { Text } from "@aws-amplify/ui-react";

export default function ModelTitle(props) {
  const { id, children, ...rest } = props;
  return (
    <Text id={id} fontSize="1.25em" fontWeight="bold" {...rest}>
      {children}
    </Text>
  );
}
