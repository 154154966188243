import { View, Grid, Flex, Image } from "@aws-amplify/ui-react";
import TitledInlineBlock from "../components/TitledInlineBlock";
import Person from "../components/Person";


export default function TeamPanel() {
  return (
    <Flex>
      <View padding="1rem" style={{"margin-top": "4rem"}}>
        <Image src="../about/Emory.jpg" alt="Emory University" height="100px" />
        <View>
          <Image src="../about/NEXMII.png" alt="NEXMII Lab" height="160px" />
          <Person
            name="Yun Wang, Ph.D."
            title="Assistant Professor"
            url="../about/YunWang.png"
          />
        </View>
      </View>
      <View padding="1rem">
        <Image src="../about/columbia.png" alt="Columbia University" height="180px" padding="1rem" />
        <View>
          <Person
              name="Andrew Laine, D.Sc."
              title="Professor, Director"
              url="../about/Andrew.png"
            />
            <Person
              name="Xuzhe Zhang"
              title="PhD Student"
              url="../about/XuzheZhang.png"
            />
        </View>
      </View>
      <View padding="1rem">
        <Image src="../about/maryland.png" alt="University of Maryland" height="100px" />
        <View>
          <Image src="../about/qualcomm.png" alt="Qualcomm" />
        </View>
        <View>
          <Person name="Ang Li, Ph.D." url="../about/Ang.png" />
        </View>
      </View>
    </Flex>
  )
}

/*
export default function TeamPanel() {
  return (
    <Grid
      columnGap="0.5rem"
      rowGap="2rem"
      templateColumns="1fr 1fr 1fr 1fr 1fr"
      templateRows="2fr"
    >
      <View className="">

      </View>

      <View>
        <Image src="../about/Emory.jpg" alt="Emory University" height="100px" />
        <Image src="../about/Nexmi.jpg" alt="NEXMII Lab" height="100px" />
        <Person
          name="Yun Wang, Ph.D."
          title="Assistant Professor"
          url="../about/YunWang.png"
        />
      </View>
      <View>
        <Image src="../about/columbia.png" alt="Columbia University" height="100px" />
      </View>
      <View>
        <Image src="../about/maryland.png" alt="University of Maryland" height="100px" />
      </View>

      <TitledInlineBlock>
        <Image src="../about/Nexmi.jpg" alt="NEXMII Lab" height="100px" />
        <Person
          name="Yun Wang, Ph.D."
          title="Assistant Professor"
          url="../about/YunWang.png"
        />
      </TitledInlineBlock>
      <View>
        <View>
          <Image
            src="../about/columbia.png"
            alt="Columbia University"
            height="100px"
          />
        </View>
        <TitledInlineBlock title="Biomedical Engineering" row="4">
          <Person
            name="Andrew Laine, D.Sc."
            title="Professor, Director"
            url="../about/Andrew.png"
          />
          <Person
            name="Xuzhe Zhang"
            title="PhD Student"
            url="../about/XuzheZhang.png"
          />
        </TitledInlineBlock>
      </View>
      <View>
        <View>
          <Image src="../about/maryland.png" alt="University of Maryland" />
        </View>
        <View>
          <Image src="../about/qualcomm.png" alt="Qualcomm" />
        </View>
        <View>
          <Person name="Ang Li, Ph.D." url="../about/Ang.png" />
        </View>
      </View>
    </Grid>
  );
}
*/