import { View } from "@aws-amplify/ui-react";
import ContactLink from "../components/ContactLink";
import { CONTACT_US_BACKGROUND_COLOR } from "../components/Theme";

export default function AppFooter() {
  return (
    <View
      style={{ flexShrink: 0 }}
      textAlign="center"
      padding="1.5em"
      backgroundColor={CONTACT_US_BACKGROUND_COLOR}
    >
      <ContactLink />
    </View>
  );
}
