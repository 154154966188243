import { useParams } from "react-router-dom";
import AuthenticatedPage from "./AuthenticatedPage";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Placeholder } from "@aws-amplify/ui-react";
import JobDetail from "../components/JobDetail";

export default function JobPage() {
  let params = useParams();
  const [job, setJob] = useState(null);
  const [tasks, setTasks] = useState([]);
  const jobId = params.jobId;
  useEffect(() => {
    const fetchData = async () => {
      var fetchedJob = await API.get("jobs", "/jobs/" + jobId);
      setJob(fetchedJob);
      const fetchedTasks = await API.get("tasks", "/tasks/?job=" + jobId);
      setTasks(fetchedTasks);
    };
    fetchData().catch(console.error);
  }, [jobId]);
  var details = <Placeholder size="large" />;
  if (job) {
    details = <JobDetail job={job} tasks={tasks} />;
  }

  return (
    <AuthenticatedPage title="Job Details" selectedNavPath="/projects">
      {details}
    </AuthenticatedPage>
  );
}
