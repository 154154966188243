import { Text } from "@aws-amplify/ui-react";
import { FaSortUp, FaSortDown } from "react-icons/fa";

export default function SortTitle(props) {
  const { title, sortDirection } = props;
  let icon = null;
  if (sortDirection > 0) {
    icon = FaSortUp;
  }
  if (sortDirection < 0) {
    icon = FaSortDown;
  }
  return (
    <Text>
      {title}
      {icon}
    </Text>
  );
}
