import { Text } from "@aws-amplify/ui-react";

export default function PageTitle(props) {
  const { children, ...rest } = props;
  return (
    <Text fontSize="2em" fontWeight="bold" marginBottom="30px" {...rest}>
      {children}
    </Text>
  );
}
