import { useState, useMemo, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { View, Grid, SelectField, Button } from "@aws-amplify/ui-react";
import PageHeader from "../components/PageHeader";
import { downloadFile } from "../api/Projects";
import AuthenticatedPage from "./AuthenticatedPage";

export default function FilesPage() {
  let params = useParams();
  const navigate = useNavigate();
  const [selectedFile, changeSelectedFile] = useState("");
  const [visualizeURL, changeVisualizeURL] = useState("");
  const location = useLocation();
  const fileOptions = [<option key="" value=""></option>];
  location.state.fileIds.forEach(function (fileId) {
    fileOptions.push(
      <option key={fileId} value={fileId}>
        {fileId}
      </option>
    );
  });
  async function onSelectedFileChange(e) {
    changeSelectedFile(e.target.value);
    console.log(e.target.value);
    const downloadURL = await downloadFile(e.target.value);
    console.log(downloadURL);
    changeVisualizeURL(downloadURL);
  }
  useEffect(() => {
    window.papaya.Container.startPapaya();
    if (visualizeURL !== "") {
      window.papaya.Container.resetViewer(0, {
        kioskMode: true,
        images: [visualizeURL],
      });
    }
    console.log();
  }, [visualizeURL]);
  return (
    <AuthenticatedPage title="Visualize files" selectedNavPath="/projects">
      <Button onClick={() => navigate(`/projects/${params.projectId}`)}>
        Back
      </Button>
      <br />
      <Grid margin="0.5rem" templateColumns="2fr 1fr" templateRows="1fr">
        <SelectField
          label="Selected File"
          value={selectedFile}
          onChange={onSelectedFileChange}
        >
          {fileOptions}
        </SelectField>
      </Grid>
      <div id="papaya_viewer" className="papaya"></div>
    </AuthenticatedPage>
  );
}
