import { Alert, Text } from "@aws-amplify/ui-react";
import { IoAlertCircle } from "react-icons/io5";

export default function ErrorAlert(props) {
  return (
    <Alert textAlign="center" variation="error" hasIcon={false} padding="1em">
      <Text>
        <IoAlertCircle size={24} style={{ verticalAlign: "middle" }} />
        &nbsp;
        {props.children}
      </Text>
    </Alert>
  );
}
