import { useNavigate } from "react-router-dom";
import { Link } from "@aws-amplify/ui-react";
import WorkingAlert from "../components/WorkingAlert";

export default function JobSubmittedAlert(props) {
  const navigate = useNavigate();
  return (
    <WorkingAlert {...props}>
      Your job is now running in the background. You can close the tab and enjoy
      a cup of coffee. Please use the&nbsp;
      <Link onClick={() => navigate(`/dashboard`)}>dashboard</Link> to monitor
      progress.
    </WorkingAlert>
  );
}
