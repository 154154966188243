import { API } from "aws-amplify";

const ENDPOINT_DOCUMENTATION = {
  Segmentation: {
    "Subcortical Segmentation": {
      Instructions:
        "This model will perform Subcortical Segmentation of one or more NIfTI images. " +
        "The input files must be in NIfTI format with extensions (.nii or .nii.gz). " +
        "The model can handle NIfTI files in various orientations.",
    },
  },
  "Modality Transfer": {
    "Convert T2 to T1": {
      Instructions:
        "This model will transform NIfTI images T2 format to T1 format. " +
        "The input filenames must contain 'T2' or 'T2w' and have extensions '.nii' or '.nii.gz'.",
    },
    "Convert T1 to T2": {
      Instructions:
        "This model will transform NIfTI images T1 format to T2 format. " +
        "The input filenames must contain 'T1' or 'T1w' and have extensions '.nii' or '.nii.gz'.",
    },
  },
};

function simplifyTags(tags) {
  const result = {};
  tags.forEach((x) => {
    result[x.Key] = x.Value;
  });
  return result;
}

function createEndpointDict(item) {
  let tags = simplifyTags(item.Tags);
  let tags_docs = ENDPOINT_DOCUMENTATION[tags.Task] || {};
  let docs = tags_docs[tags.Model] || { Instructions: "" };
  return {
    EndpointName: item.EndpointName,
    Task: tags.Task,
    Model: tags.Model,
    Version: tags.Version,
    SourceCodeURL: tags.SourceCodeURL,
    InfrastructureRoles: tags.InfrastructureRoles,
    FilenameSchema: tags.FilenameSchema,
    Instructions: docs.Instructions,
  };
}

const T1_ERROR_MSG =
  "Only input filenames containing T1 or T2w are allowed for this model.";
const T2_ERROR_MSG =
  "Only input filenames containing T2 or T2w are allowed for this model.";

export function verifyFilename(endpoint, filename) {
  if (endpoint.FilenameSchema) {
    const first_part = filename.split(".")[0];
    if (endpoint.FilenameSchema === "T1toT2") {
      if (!first_part.includes("T1")) {
        throw new Error(T1_ERROR_MSG);
      }
    } else if (endpoint.FilenameSchema === "T2toT1") {
      if (!first_part.includes("T2")) {
        throw new Error(T2_ERROR_MSG);
      }
    }
  }
}

function groupEndpoints(endpoints) {
  const results = {};
  endpoints.forEach(function (endpoint) {
    var task = results[endpoint.Task];
    if (!task) {
      task = {};
      results[endpoint.Task] = task;
    }
    var model = task[endpoint.Model];
    if (!model) {
      model = [];
      task[endpoint.Model] = model;
    }
    model.push(endpoint);
  });
  return results;
}

export async function getGroupedModels() {
  const result = await API.get("endpoints", "/endpoints");
  const endpoints = result.map(createEndpointDict);
  const tasks = groupEndpoints(endpoints);
  return tasks;
}

export async function getEndpointDetails(endpoint_name) {
  return await API.get("endpoints", `/endpoints/${endpoint_name}`);
}

export function getMostRecentEndpointName(groupedModels, task, model) {
  const versions = groupedModels[task][model];
  return versions[0].EndpointName;
}

export async function getEndpointDocumentation(endpoint) {
  return ENDPOINT_DOCUMENTATION[endpoint.Task][endpoint.Model];
}
