import { Grid, Image, Text, Link } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";

export default function EasyToUsePanel() {
  const navigate = useNavigate();
  const itemFontSize = "1.25em";

  return (
    <Grid
      templateColumns="64px 1fr"
      alignItems="center"
      margin="1em"
      marginBottom="2.5em"
    >
      <Text
        color="white"
        fontWeight="bold"
        fontSize="1.5em"
        columnStart="1"
        columnEnd="-1"
      >
        FINNEAS is easy-to-use; no programming experience is needed!
      </Text>
      <Text
        color="white"
        paddingTop="8px"
        paddingBottom="1em"
        columnStart="1"
        columnEnd="-1"
        fontSize="1.5em"
      >
        Get going quickly in just a couple steps:
      </Text>
      <Image src="home/Signin.png" width="64px" />
      <Text as="span" color="white" paddingLeft="24px" fontSize={itemFontSize}>
        <Link
          color="white"
          textDecoration="underline"
          onClick={() => navigate("/login")}
        >
          Register
        </Link>
        &nbsp; and sign in
      </Text>
      <Image src="home/SecureStorage.png" width="64px"></Image>
      <Text color="white" paddingLeft="24px" fontSize={itemFontSize}>
        Setup Secure Storage
      </Text>
      <Image src="home/CreateProject.png" width="64px"></Image>
      <Text color="white" paddingLeft="24px" fontSize={itemFontSize}>
        Create a project
      </Text>
      <Image src="home/UploadImages.png" width="64px"></Image>
      <Text color="white" paddingLeft="24px" fontSize={itemFontSize}>
        Upload an MRI
      </Text>
      <Image src="home/RunModel.png" width="64px"></Image>
      <Text color="white" paddingLeft="24px" fontSize={itemFontSize}>
        Run model and download the results
      </Text>
    </Grid>
  );
}
