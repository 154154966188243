import { View, Flex, Text, Image } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import NavButton from "../components/NavButton";
import SignInOutPanel from "../components/SignInOutPanel";

export default function NavBar(props) {
  const { selectedNavPath } = props;
  const location = useLocation();
  console.log(location.pathname);
  const navigate = useNavigate();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  return (
    <View
      as="nav"
      backgroundColor={"#474039"}
      minWidth="900px"
      color="white"
      height="48px"
      paddingRight="4em"
    >
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
      >
        <Image
          paddingLeft="6px"
          paddingRight="6px"
          paddingTop="2px"
          paddingBottom="2px"
          backgroundColor="white"
          src="/finneas_logo_small.png"
          height="48px"
        />
        <Text
          as="span"
          paddingTop="6px"
          color="white"
          fontSize="1.5em"
          fontWeight={600}
        >
          FINNEAS.AI
        </Text>
        <NavButton
          label="Home"
          path="/"
          selectedNavPath={selectedNavPath}
          navigate={navigate}
        ></NavButton>
        <NavButton
          label="Models"
          path="/models"
          selectedNavPath={selectedNavPath}
          navigate={navigate}
        ></NavButton>
        <NavButton
          label="Projects"
          path="/projects"
          selectedNavPath={selectedNavPath}
          navigate={navigate}
        ></NavButton>
        <NavButton
          label="Dashboard"
          path="/dashboard"
          selectedNavPath={selectedNavPath}
          navigate={navigate}
        ></NavButton>
        <NavButton
          label="About"
          path="/about"
          selectedNavPath={selectedNavPath}
          navigate={navigate}
        ></NavButton>

        <Text style={{ flexGrow: 1 }}></Text>
        <SignInOutPanel user={user} navigate={navigate} signOut={signOut} />
      </Flex>
    </View>
  );
}
