import { Text, Link } from "@aws-amplify/ui-react";

export default function ModelPaper(props) {
  const { url } = props;
  return (
    <Text marginTop="10px">
      <Text as="span" fontWeight="bold">
        Paper:&nbsp;
      </Text>
      <Link isExternal={true} href={url}>
        {url}
      </Link>
    </Text>
  );
}
