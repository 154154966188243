import NavBar from "../components/NavBar";
import { Flex, View, Text, Image } from "@aws-amplify/ui-react";
import ContentView from "../components/ContentView";
import PageTitle from "../components/PageTitle";

export default function AboutDataSecurityPage() {
  return (
    <>
      <NavBar selectedNavPath="/about" />
      <ContentView>
        <PageTitle>Data Security</PageTitle>
        <Flex>
          <View>
            <Text maxWidth="650px" fontSize="1.25em">
              To keep your data private a secure storage environment is created
              for each user. This storage environment consists of an S3 Bucket
              (a file storage location), an encryption key, and a Security
              Policy. All files uploaded to the S3 Bucket are encrypted with the
              encryption key. The Security Policy ensure that no other users can
              encrypt, decrypt, or otherwise access your data.
            </Text>
          </View>
          <View textAlign="center">
            <Text
              whiteSpace="nowrap"
              fontSize="1.25em"
              fontWeight="bold"
              backgroundColor="#0261BA"
              color="white"
              paddingTop="0.5em"
              paddingBottom="0.5em"
              paddingLeft="1em"
              paddingRight="1em"
            >
              Secure Storage Environment
            </Text>
            <Text
              textAlign="center"
              whiteSpace="nowrap"
              fontSize="1.25em"
              fontWeight="bold"
              marginTop="10px"
            >
              Security Policy
            </Text>
            <View
              backgroundColor="#D9E7F5"
              border="4px dashed black"
              borderRadius="10px"
              padding="1em"
              marginLeft="1em"
              marginRight="1em"
              marginBottom="1em"
            >
              <Text
                textAlign="center"
                whiteSpace="nowrap"
                fontSize="1.25em"
                fontWeight="bold"
              >
                Encryption Key
              </Text>
              <Image src="/security/Key.png" width="100px" />
              <Text
                textAlign="center"
                whiteSpace="nowrap"
                fontSize="1.25em"
                fontWeight="bold"
              >
                S3 Bucket
              </Text>
              <View
                backgroundColor="white"
                borderRadius="10px"
                border="3px solid black"
                padding="1em"
              >
                <Image src="/security/EncryptedImages.png" width="300px" />
              </View>
            </View>
          </View>
        </Flex>
      </ContentView>
    </>
  );
}
