import { Link } from "@aws-amplify/ui-react";
import { SELECT_NAV_LINK_BOTTOM_BORDER } from "../components/Theme";

export default function NavButton(props) {
  const { navigate, selectedNavPath, path, label } = props;
  let borderBottomStyle = null;
  let fontWeightValue = 300;
  if (selectedNavPath === path) {
    borderBottomStyle = SELECT_NAV_LINK_BOTTOM_BORDER;
    fontWeightValue = 600;
  }
  return (
    <Link
      paddingTop="12px"
      variation="link"
      color="white"
      onClick={() => navigate(path)}
      size="small"
      fontWeight={fontWeightValue}
      paddingLeft="1em"
      paddingRight="1em"
      style={{ borderBottom: borderBottomStyle }}
    >
      {label}
    </Link>
  );
}
