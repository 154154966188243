import { Storage, Auth, API } from "aws-amplify";

async function getConfig() {
  const { attributes } = await Auth.currentUserInfo();
  const bucketName = "findata-" + attributes.sub;
  return {
    bucket: bucketName,
    customPrefix: { public: "" },
  };
}

function keyContainsSlash(s3FileObject) {
  return s3FileObject.key.includes("/");
}

function getTopFolder(s3FileObject) {
  return s3FileObject.key.split("/")[0];
}

function isFile(s3FileObject) {
  return !s3FileObject.key.endsWith("/");
}

export async function fetchProjects() {
  // Returns the names of the top level folders within the current
  // user's private folder of the S3 bucket.
  const s3config = await getConfig();
  const s3FileObjects = await Storage.list("", s3config);
  // Since Storage.list returns all files in the bucket
  // we need to filter out top level files, extract the top level folder,
  // and make the folder names unique
  const projectNames = s3FileObjects.filter(keyContainsSlash).map(getTopFolder);
  const setProjectNames = new Set(projectNames);
  setProjectNames.delete(".jobs");
  const uniqueProjectNames = [...setProjectNames];
  return uniqueProjectNames;
}

export async function createProject(name) {
  const folderPath = name + "/";
  const s3config = await getConfig();
  const existingProjects = await Storage.list(folderPath, s3config);
  if (existingProjects.length) {
    throw new Error(`A project named '${name}' already exists.`);
  }
  return await Storage.put(folderPath, "", s3config);
}

export async function uploadFiles(projectName, files) {
  const folderPath = projectName + "/";
  const s3config = await getConfig();
  try {
    for (const uploadFile of files) {
      await Storage.put(folderPath + uploadFile.name, uploadFile, s3config);
    }
  } catch (error) {
    console.log("Error uploading file: ", error);
  }
}

export async function downloadFile(key) {
  const s3config = await getConfig();
  return await Storage.get(key, s3config);
}

export async function deleteFile(key) {
  const s3config = await getConfig();
  return await Storage.remove(key, s3config);
}

export async function listFiles(projectName) {
  const s3config = await getConfig();
  const folderPath = projectName + "/";
  const s3FileObjects = await Storage.list(folderPath, s3config);
  return s3FileObjects
    .filter(isFile)
    .map((obj) => ({ ...obj, name: obj.key.replace(folderPath, "") }));
}

export async function getBucketInfo() {
  const s3config = await getConfig();
  return {
    bucket: s3config.bucket,
  };
}

export function removeKeyPrefix(key) {
  // Converts full S3 object key to a key compatible with the Storage library
  // This is done by removing the leading "private/<userid>/" part"
  return key.replace(/^private\/.*?\//, "");
}

export async function getBucket() {
  const result = await API.get("user", "/user").catch((err) => {
    if (err.response.status !== 404) {
      console.error(err);
    }
    return {
      bucket: null,
    };
  });
  return result.bucket;
}
