import { SelectField } from "@aws-amplify/ui-react";
import SimpleOptions from "../components/SimpleOptions";

export default function EnhancedSelectField(props) {
  const { optionValues, ...rest } = props;

  return (
    <SelectField className="EnhancedSelectField" marginBottom="1rem" {...rest}>
      <SimpleOptions optionValues={optionValues} />
    </SelectField>
  );
}
