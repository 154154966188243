import { View } from "@aws-amplify/ui-react";

export default function ContentView(props) {
  const { children, rest } = props;
  return (
    <View className="mainLeftRightPadding mainTopBottomPadding" {...rest}>
      {children}
    </View>
  );
}
