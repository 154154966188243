import NavBar from "../components/NavBar";
import EasyToUsePanel from "../components/EasyToUsePanel";
import {
  Grid,
  View,
  Button,
  Image,
  Text,
  Flex,
  Link,
} from "@aws-amplify/ui-react";
import ImportantButton from "../components/ImportantButton";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import {
  EASY_TO_USE_BACKGROUND_COLOR,
  LEARN_ABOUT_MODELS_BACKGROUND_COLOR,
  MODEL_COMPARE_BUTTON_COLOR,
} from "../components/Theme";

export default function HomePage() {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  let registerButton = null;
  function register() {
    navigate("/login");
  }
  if (!user) {
    registerButton = (
      <ImportantButton
        paddingLeft="2em"
        paddingRight="2em"
        paddingTop="1em"
        paddingBottom="1em"
        onClick={register}
      >
        Register
      </ImportantButton>
    );
  }
  return (
    <>
      <NavBar selectedNavPath="/" />
      <main class="mainLeftRightPadding">
        <Grid
          columnGap="0.5rem"
          rowGap="0.5rem"
          templateColumns="1.5fr 1fr"
          templateRows="0fr 1fr 0fr"
        >
          <View class="homeHeroLogo" columnStart="1" columnEnd="-1">
            <Image src="hero.png" alt="FINNEAS.AI" />
          </View>
          <View>
            <View class="homeHeroText">
              <Text as="span" fontWeight="bold">
                FINNEAS is a Federated Infant Neuroimaging Analysis platform
              </Text>
              <Text as="span">
                &nbsp;that helps researchers from non-technical backgrounds use
                novel AI models while preserving{" "}
                <Link
                  textDecoration="underline"
                  onClick={() => navigate("/about/data-security")}
                >
                  data privacy
                </Link>
                .
              </Text>
            </View>
            {registerButton}
          </View>
          <View
            class="homeEasyToUsePanel"
            backgroundColor={EASY_TO_USE_BACKGROUND_COLOR}
            color="white"
            padding="20px"
            marginBottom="40px"
          >
            <EasyToUsePanel />
          </View>
        </Grid>
      </main>
      <Flex
        direction="row"
        class="mainLeftRightPadding"
        paddingTop="5em"
        paddingBottom="4em"
        display="flex"
        backgroundColor={LEARN_ABOUT_MODELS_BACKGROUND_COLOR}
      >
        <View margin="30px">
          <Link onClick={() => navigate("/models")}>
            <Image width="450" src="home/ModelCompareImage.png" />
          </Link>
        </View>
        <View>
          <Text fontSize="1.75em" fontWeight="bold">
            Learn about the AI models
          </Text>
          <View fontSize="1.25em">
            <ul>
              <li>
                MapSeg - Multi-site, Multi-age, and Multi-modal Subcortical
                Brain Segmentation from Birth to 24 Months
              </li>
              <li>
                PTNet3D - Longitudinal Infant Brain Synthesis Using PTNet:
                Imputing Missing or Corrupted MRI Data from Birth to 24 Months
              </li>
            </ul>
          </View>
          <Button
            backgroundColor="white"
            color={MODEL_COMPARE_BUTTON_COLOR}
            onClick={() => navigate("/models")}
            paddingLeft="2em"
            paddingRight="2em"
            paddingTop="1em"
            paddingBottom="1em"
          >
            View Model Comparisions
          </Button>
        </View>
      </Flex>
    </>
  );
}
