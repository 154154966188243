import "./App.css";
import { Authenticator, Flex } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/DashboardPage";
import ProjectListPage from "./pages/ProjectListPage";
import NewProjectPage from "./pages/NewProjectPage";
import ProjectPage from "./pages/ProjectPage";
import FilesPage from "./pages/FilesPage";
import UploadFilesPage from "./pages/UploadFilesPage";
import LaunchPage from "./pages/LaunchPage";
import LoginPage from "./pages/LoginPage";
import VisualizeFilesPage from "./pages/VisualizeFilesPage";
import AboutPage from "./pages/AboutPage";
import JobPage from "./pages/JobPage";
import AppFooter from "./components/AppFooter";
import ModelsPage from "./pages/ModelsPage";
import AuditPage from "./pages/AuditPage";
import AboutDataSecurityPage from "./pages/AboutDataSecurityPage";

function App() {
  return (
    <Flex direction="column" height="100vh" gap="0px">
      <Authenticator.Provider>
        <div className="App" style={{ flex: "1 0 auto" }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/models" element={<ModelsPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="projects">
              <Route path=":projectId">
                <Route path="files" element={<FilesPage />} />
                <Route path="upload" element={<UploadFilesPage />} />
                <Route path="launch" element={<LaunchPage />} />
                <Route path="visualize" element={<VisualizeFilesPage />} />
                <Route index element={<ProjectPage />} />
              </Route>
              <Route path="new" element={<NewProjectPage />} />
              <Route path="audit-log" element={<AuditPage />} />
              <Route index element={<ProjectListPage />} />
            </Route>
            <Route path="jobs">
              <Route path=":jobId" element={<JobPage />}></Route>
            </Route>
            <Route path="about">
              <Route path="data-security" element={<AboutDataSecurityPage />} />
              <Route index element={<AboutPage />} />
            </Route>
          </Routes>
        </div>
        <AppFooter />
      </Authenticator.Provider>
    </Flex>
  );
}

export default App;
