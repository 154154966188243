// components/Login.js
import { useEffect } from "react";

import {
  Authenticator,
  useAuthenticator,
  View,
  Image,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { useNavigate, useLocation } from "react-router";

export default function LoginPage() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/projects";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  const components = {
    Header() {
      return (
        <View textAlign="center">
          <Image alt="FINNEAS.AI" src="hero.png" />
        </View>
      );
    },
  };
  return (
    <View className="auth-wrapper">
      <Authenticator components={components}></Authenticator>
    </View>
  );
}
