import { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Placeholder,
  ButtonGroup,
  View,
  TextField,
} from "@aws-amplify/ui-react";
import ContentView from "../components/ContentView";
import PageTitle from "../components/PageTitle";
import ActionCard from "../components/ActionCard";
import InfoCard from "../components/InfoCard";
import BackButton from "../components/BackButton";
import ImportantButton from "../components/ImportantButton";
import EnhancedSelectField from "../components/EnhancedSelectField";
import JobSubmittedAlert from "../components/JobSubmittedAlert";
import ErrorAlert from "../components/ErrorAlert";
import WorkingAlert from "../components/WorkingAlert";
import LaunchModelInfo from "../components/LaunchModelInfo";

import { createMLGrants, createJob } from "../api/Jobs";
import {
  getGroupedModels,
  getEndpointDetails,
  verifyFilename,
} from "../api/models";
import { getBucketInfo } from "../api/Projects";

const DEFAULT_TASK = "Segmentation";
const DEFAULT_MODEL = "Subcortical Segmentation";

function loadingActionContent() {
  return (
    <>
      <Placeholder
        height="2.5rem"
        size="large"
        marginTop="4em"
        marginBottom="3em"
      />
      <Placeholder height="2.5rem" size="large" marginBottom="3em" />
      <Placeholder height="2.5rem" size="large" marginBottom="3em" />
      <Placeholder
        height="2.5rem"
        size="large"
        marginTop="8em"
        marginBottom="2em"
      />
      <ButtonGroup margin="1rem">
        <BackButton />
      </ButtonGroup>
    </>
  );
}

export default function LaunchPanel(props) {
  const { projectId, files } = props;
  const [endpoint, setEndpoint] = useState(null);
  const [groupedModels, setGroupedModels] = useState(null);
  const [grantUUID, setGrantUUID] = useState(null);
  const [outputPrefix, setOutputPrefix] = useState("");
  const [jobStatus, setJobStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [jobSubmitted, setJobSubmitted] = useState(false);
  const [disableStartButton, setDisableStartButton] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getGroupedModels();
      setEndpoint(result[DEFAULT_TASK][DEFAULT_MODEL][0]);
      setGroupedModels(result);
    };
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setGrantUUID(crypto.randomUUID());
  }, []);

  function onChangeTask(newTask) {
    const newModel = Object.keys(groupedModels[newTask])[0];
    setEndpoint(groupedModels[newTask][newModel][0]);
  }

  function onChangeModel(newModel) {
    setEndpoint(groupedModels[endpoint.Task][newModel][0]);
  }

  function onChangeVersion(newVersion) {
    const models = groupedModels[endpoint.Task][endpoint.Model];
    const versionIndex = models.findIndex((x) => (x.Version = newVersion));
    setEndpoint(models[versionIndex]);
  }

  async function onClickStart() {
    try {
      setErrorMessage("");
      if (endpoint.FilenameSchema) {
        files.forEach((filename) => {
          // raises error if the filename is unacceptable for the current model
          verifyFilename(endpoint, filename);
        });
      }
      window.scrollTo(0, 0);
      setDisableStartButton(true);
      setJobStatus(
        "Granting temporary access to ML infrastructure. Do not close this tab."
      );
      const endpointName = endpoint.EndpointName;
      const { InfrastructureRoles } = await getEndpointDetails(endpointName);
      const grants = await createMLGrants(grantUUID, InfrastructureRoles);
      const { bucket } = await getBucketInfo();
      setJobStatus("Launching tasks. Do not close this tab.");
      const numTasks = await createJob(
        bucket,
        endpointName,
        outputPrefix,
        files,
        grants,
        endpoint.FilenameSchema
      );
      console.log(`${numTasks} task(s) created.`);
      setJobSubmitted(true);
    } catch (error) {
      window.scrollTo(0, 0);
      console.log(error);
      setErrorMessage(error.toString());
      setDisableStartButton(false);
    }
  }

  var alert = null;
  if (errorMessage) {
    alert = <ErrorAlert>{errorMessage}</ErrorAlert>;
  } else if (jobSubmitted) {
    // the job is fully submitted so the user can close their tab
    alert = <JobSubmittedAlert />;
  } else if (jobStatus) {
    // the job is being submitted
    alert = <WorkingAlert>{jobStatus}</WorkingAlert>;
  }

  let actionContent = loadingActionContent();
  let infoContent = <Placeholder height="20em" />;
  if (groupedModels && endpoint) {
    const taskNames = Object.keys(groupedModels);
    const modelNames = Object.keys(groupedModels[endpoint.Task]);
    const versionNames = groupedModels[endpoint.Task][endpoint.Model].map(
      (x) => x.Version
    );
    actionContent = (
      <>
        <Text fontWeight="bold" fontSize="large">
          Select a Model
        </Text>
        <View marginTop="1rem" marginBottom="2rem">
          <EnhancedSelectField
            label="Task"
            value={endpoint.Task}
            onChange={(e) => onChangeTask(e.target.value)}
            optionValues={taskNames}
          />
          <EnhancedSelectField
            label="Model"
            value={endpoint.Model}
            onChange={(e) => onChangeModel(e.target.value)}
            optionValues={modelNames}
          />
          <EnhancedSelectField
            label="Version"
            value={endpoint.Version}
            onChange={(e) => onChangeVersion(e.target.value)}
            optionValues={versionNames}
          />
        </View>
        <Text fontWeight="bold" fontSize="large">
          Configure Task
        </Text>
        <View marginTop="1rem" marginBottom="2rem">
          <TextField
            backgroundColor="white"
            label="Output Filename Prefix"
            descriptiveText="Leave blank to use the model name"
            title="This value will be prepended to all output filenames"
            placeholder={endpoint.EndpointName + "-"}
            value={outputPrefix}
            onChange={(e) => setOutputPrefix(e.target.value)}
          />
        </View>
        <ButtonGroup>
          <ImportantButton disabled={disableStartButton} onClick={onClickStart}>
            Start Task
          </ImportantButton>
          <BackButton />
        </ButtonGroup>
      </>
    );
    infoContent = <LaunchModelInfo endpoint={endpoint} files={files} />;
  }
  return (
    <>
      {alert}
      <ContentView>
        <PageTitle>Project {projectId} - Launch Task</PageTitle>
        <Flex>
          <ActionCard>{actionContent}</ActionCard>
          <InfoCard>{infoContent}</InfoCard>
        </Flex>
      </ContentView>
    </>
  );
}
