import { View } from "@aws-amplify/ui-react";
import { ACTION_PANEL_COLOR } from "./Theme";

export default function ActionCard(props) {
  const { children, ...rest } = props;
  return (
    <View backgroundColor={ACTION_PANEL_COLOR} className="actionCard" {...rest}>
      {children}
    </View>
  );
}
