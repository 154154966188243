import SecondaryButton from "../components/SecondaryButton";
import { useNavigate } from "react-router-dom";

export default function BackButton(props) {
  const { to } = props;
  let navigateTo = -1;
  if (to) {
    navigateTo = to;
  }
  const navigate = useNavigate();

  return (
    <SecondaryButton onClick={() => navigate(navigateTo)}>Back</SecondaryButton>
  );
}
