import { Text, Flex } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { useState } from "react";
import { FaKey } from "react-icons/fa";
import { BsBucketFill } from "react-icons/bs";
import PageTitle from "../components/PageTitle";
import ContentView from "../components/ContentView";
import ImportantButton from "../components/ImportantButton";
import ActionCard from "../components/ActionCard";
import InfoCard from "../components/InfoCard";
import WorkingAlert from "../components/WorkingAlert";

export default function SetupStoragePanel(props) {
  const { afterSetup } = props;
  const [working, setWorking] = useState(false);
  async function createSecureStorage() {
    setWorking(true);
    const result = await API.post("storage", "/storage", {
      body: {},
    });
    console.log(result);
    setWorking(false);
    afterSetup();
  }
  let workingContent = null;
  if (working) {
    workingContent = (
      <WorkingAlert>
        <Text as="span" fontWeight="bold">
          Creating your Secure Storage Environment:
        </Text>
        <Text>
          Creating a key <FaKey /> to encrypt your files
        </Text>
        <Text>
          Creating a bucket <BsBucketFill /> to hold your files
        </Text>
        <Text>
          Applying setup so only you can encrypt/decrypt with your key
        </Text>
      </WorkingAlert>
    );
  }

  return (
    <>
      {workingContent}
      <ContentView>
        <PageTitle>Secure Storage</PageTitle>
        <Flex>
          <ActionCard>
            <Text fontWeight="bold" fontSize="large">
              Storage Setup
            </Text>
            <Text as="p" marginTop="1em" marginBottom="1em">
              Before using finneas.ai a secure storage environment must be
              created.
            </Text>
            <ImportantButton
              isLoading={working}
              loadingText="Please wait..."
              onClick={createSecureStorage}
            >
              Setup Secure Storage
            </ImportantButton>
          </ActionCard>
          <InfoCard>
            <Text fontWeight="bold" fontSize="large">
              Details
            </Text>
            <Text as="p">
              A private storage location(also known as a bucket) will be created
              where all your files will be stored. A private key will be created
              that only you can use. Every file in the bucket will be encrypted
              using your private key.
            </Text>
          </InfoCard>
        </Flex>
      </ContentView>
    </>
  );
}
