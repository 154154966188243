import {
  ButtonGroup,
  Button,
  Link,
  View,
  Text,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { downloadFile } from "../api/Projects";

function getFilename(filepath) {
  const inputPathParts = filepath.split("/");
  return inputPathParts[inputPathParts.length - 1];
}

async function onClickDownload(longKey) {
  const key = longKey.replace(/private\/.*?\//, "");
  const url = await downloadFile(key);
  window.open(url, "_blank");
}

export default function JobDetail(props) {
  let navigate = useNavigate();
  const { job, tasks } = props;
  var taskList = [];
  if (tasks.length > 0) {
    tasks.forEach(function (task) {
      const inputFilename = getFilename(task.input.object);
      const outputFilename = getFilename(task.output.object);
      var outputCell = <TableCell>{outputFilename}</TableCell>;
      if (task.status === "S") {
        outputCell = (
          <TableCell>
            <Link onClick={() => onClickDownload(task.output.object)}>
              {outputFilename}
            </Link>
          </TableCell>
        );
      }

      taskList.push(
        <TableRow key={task.id}>
          <TableCell>
            <Link onClick={() => onClickDownload(task.input.object)}>
              {inputFilename}
            </Link>
          </TableCell>
          {outputCell}
          <TableCell>{task.message}</TableCell>
        </TableRow>
      );
    });
  }
  console.log(job);
  console.log(tasks);
  return (
    <>
      <View>
        <Text as="span" fontWeight="bold">
          Project:&nbsp;
        </Text>
        <Link onClick={() => navigate("/projects/" + job.projectName)}>
          {job.projectName}
        </Link>
      </View>
      <View>
        <Text as="span" fontWeight="bold">
          Model:&nbsp;
        </Text>
        <Text as="span">{job.model}</Text>
      </View>
      <View>
        <Text as="span" fontWeight="bold">
          Created:&nbsp;
        </Text>
        <Text as="span">{job.created}</Text>
      </View>
      <br />
      <Text fontWeight="bold">Tasks</Text>
      <Table variation="striped">
        <TableHead>
          <TableRow>
            <TableCell as="th">Input File</TableCell>
            <TableCell as="th">Output File</TableCell>
            <TableCell as="th">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{taskList}</TableBody>
      </Table>
    </>
  );
}
