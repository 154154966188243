import { useParams } from "react-router-dom";
import FileUploadPanel from "../components/FileUploadPanel";
import AuthenticatedPage from "./AuthenticatedPage";

export default function UploadFilesPage() {
  let params = useParams();
  return (
    <AuthenticatedPage selectedNavPath="/projects">
      <FileUploadPanel project={params.projectId} />
    </AuthenticatedPage>
  );
}
